<template>
  <modal-comp
    :show="state.showModal"
    title-text="Set delay Time"
    @hide="fireEvent('update:modelValue', $event)"
    @submit="handleDataUpdate"
    :loading="state.formSubmitting"
    style="width: 500px"
    title-icon-class="mr-2 icon-timelapse"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="delivery" class="col-form-label">
            Delivery Delay Time
            <span class="text-danger">*</span>
            <form-input-help>in minute</form-input-help>
          </label>
          <input
            type="number"
            class="form-control"
            id="delivery"
            placeholder="Maximum distance"
            v-model.trim="state.form.deliveryDelayTime.value"
            autocomplete="off"
            required
          >
          <form-error-msg :error="state.form.deliveryDelayTime.error"/>
        </div>


        <div class="form-group">
          <label for="collection" class="col-form-label">
            Collection Delay Time
            <span class="text-danger">*</span>
            <form-input-help>in minute</form-input-help>
          </label>
          <input
            type="number"
            class="form-control"
            id="collection"
            placeholder="Maximum distance"
            v-model.trim="state.form.collectionDelayTime.value"
            autocomplete="off"
            required
          >
          <form-error-msg :error="state.form.collectionDelayTime.error"/>
        </div>

      </div>
    </div>
  </modal-comp>
</template>

<script>

import { defineComponent, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import Toaster from '@/utils/Toaster';
import { decimal, minValue, required } from '@vuelidate/validators';
import FormInputHelp from '@/components/Util/FormInputHelp';
import useVuelidate from '@vuelidate/core';
import ErrorHelper from '@/utils/ErrorHelper';

export default defineComponent({
  name: 'DeliveryChargeDelayTiming',
  components: { FormInputHelp, FormErrorMsg, ModalComp },
  emits: ['update:modelValue', 'hide'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {

    const store = useStore();

    const state = reactive({

      showModal: props.modelValue,
      formSubmitting: false,

      form: {

        deliveryDelayTime: {
          value: 0,
          error: false
        },

        collectionDelayTime: {
          value: 0,
          error: false
        }
      }
    });

    const fireEvent = (event, value) => emit(event, value);

    const getTiming = () => {

      const deliveryDelayTime = computed(() => store.getters['setting/getSettings']('delivery_order_delivery_time', true, ''));
      const collectionDelayTime = computed(() => store.getters['setting/getSettings']('collection_order_delivery_time', true, ''));

      state.form.deliveryDelayTime.value = deliveryDelayTime.value;
      state.form.collectionDelayTime.value = collectionDelayTime.value;
    };

    const validationRules = {
      form: {
        deliveryDelayTime: {
          value: {
            required,
            decimal,
            minValue: minValue(1),
          }
        },

        collectionDelayTime: {
          value: {
            required,
            decimal,
            minValue: minValue(1),
          }
        }
      }
    };

    const validateData = useVuelidate(validationRules, state, { $autoDirty: true });

    const validateSubmission = () => {

      let dataIsValid = true;
      ErrorHelper.checkValidity(validateData);

      const form = state.form;

      for (let key in form) {

        if (form[key].error) {

          dataIsValid = false;
          break;
        }
      }

      return dataIsValid;
    };

    const handleDataUpdate = async () => {

      let validated = validateSubmission();

      if (!validated) {

        Toaster.warning({
          message: 'Please Fix error First'
        })

        return
      }

      state.formSubmitting = true;

      await handleDataSave();

      state.formSubmitting = false;
    };

    const handleDataSave = async () => {

      const dataToSubmit = {
        delivery_delay_time: state.form.deliveryDelayTime.value,
        collection_delay_time: state.form.collectionDelayTime.value
      };

      try {

        const response = await store.dispatch('setting/updateDelayTimeSetting', dataToSubmit);

        Toaster.successAlt({
          title: 'Updated',
          message: response.data.message || 'Delay time successfully updated'
        });

        fireEvent('update:modelValue', false);

      } catch (e) {

        ErrorHelper.mapServerError(e, (err, ex) => {

          state.form.deliveryDelayTime.error = ex(err.delivery_delay_time);
          state.form.collectionDelayTime.error = ex(err.collection_delay_time);

        });

        Toaster.error({
          title: 'Error',
          message: e.message || 'Something went wrong'
        });

      }

    };

    watch(() => props.modelValue, (nv) => {

      if (nv) {
        getTiming();
      }
      state.showModal = nv;
      fireEvent('update:modelValue', nv);
    });

    watch(validateData, () => {

      ErrorHelper.getValidationErrors(validateData, (errors) => {
        state.form.collectionDelayTime.error = errors['form.collectionDelayTime.value'] || false;
        state.form.deliveryDelayTime.error = errors['form.deliveryDelayTime.value'] || false;
      }, {
        'form.collectionDelayTime.value': 'Collection Delay Time',
        'form.deliveryDelayTime.value': 'Delivery Delay Time',
      });

    });

    return {
      state,
      fireEvent,
      handleDataUpdate
    };

  }
});
</script>
