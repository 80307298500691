import { ElMessage } from 'element-plus';

export default class Notification {
  /**
   *
   * @param message {String}
   * @param title {String}
   * @param duration {Number}
   * @param closeCallback {Function}
   */
  static success = (message, title = '', duration = 3000, closeCallback = null) => {
    this.fireNotification(title, message, duration, 'success', closeCallback);
  };

  /**
   *
   * @param message {String}
   * @param title {String}
   * @param duration {Number}
   * @param closeCallback {Function}
   */
  static info = (message, title = '', duration = 3000, closeCallback = null) => {
    this.fireNotification(title, message, duration, 'info', closeCallback);
  };

  /**
   *
   * @param message {String}
   * @param title {String}
   * @param duration {Number}
   * @param closeCallback {Function}
   */
  static warning = (message, title = '', duration = 3000, closeCallback = null) => {
    this.fireNotification(title, message, duration, 'warning', closeCallback);
  };

  /**
   *
   * @param message {String}
   * @param title {String}
   * @param duration {Number}
   * @param closeCallback {Function}
   */
  static error = (message, title = '', duration = 3000, closeCallback = null) => {
    this.fireNotification(title, message, duration, 'error', closeCallback);
  };

  /**
   *
   * @param title {String}
   * @param message {String}
   * @param duration {Number}
   * @param type {string}
   * @param closeCallback {function}
   * @param showClose {boolean}
   */
  static fireNotification = (title, message, duration = 3000, type, closeCallback = null, showClose = true) => {

    let config = {
      title: title,
      message: message,
      type: type,
      duration: duration,
      showClose: showClose,
      onClose: (t) => {
        if (typeof closeCallback === 'function') {
          closeCallback(t);
        }
      }
    };

    ElMessage(config);
  };
}
