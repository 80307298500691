<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-title">
          <div class="card-header card-header-with-btn bg-light">
            <div class="card-title">Delivery Charges</div>
            <div class="d-flex flex-row gap-2" v-if="isAuthorized(permissions.delivery_charge_create)">
              <button
                v-if="isAuthorized(permissions.delivery_charge_create)"
                class="btn btn-sm btn-outline-primary p-2"
                @click="state.showDelayAction = !state.showDelayAction"
              >
                <i class="icon-label"/>
                Set Delay time
              </button>
              <button
                class="btn btn-sm btn-primary p-2"
                @click="handleShowHideAction"
                v-if="isAuthorized(permissions.delivery_charge_create)"
              >
                <i class="icon-add"></i>
                Add New
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" v-loading="state.loading">

          <ItemTable
            :data="state.data"
            :columns="state.columnMap"
            :show-view="false"
            :show-actions="isAuthorized(permissionsGroup.deliveryCharges)"
          >
            <template #action="{ item }">
              <EditButton
                v-if="isAuthorized(permissions.delivery_charge_update)"
                @click="handleDataAction(item, LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.delivery_charge_delete)"
                @click="handleDataAction(item, LIST_ITEM_ACTION_DELETE)"
              />
            </template>

            <template #amount="{item}">
              {{ currency(item.amount) }}
            </template>

            <template #max_order="{item}">
              {{ currency(item.max_order) }}
            </template>

          </ItemTable>

        </div>
      </div>
    </div>
    <delivery-charge-action
      v-model="state.showAction"
      :item-to-update="state.selectedItem"
      @hidden="handleUpdateAction(false)"
    />
    <delivery-charge-delay-timing v-model="state.showDelayAction"/>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import ItemTable from '@/components/Util/ItemTable';
import { currency, msgBox } from '@/Mixins/appHelper';
import DeliveryChargeAction from '@/components/DeliveryCharge/DeliveryChargeAction';
import { LIST_ITEM_ACTION_DELETE, LIST_ITEM_ACTION_UPDATE } from '@/utils/constants';
import Toaster from '@/utils/Toaster';
import DeliveryChargeDelayTiming from '@/components/DeliveryCharge/DeliveryChargeDelayTiming';
import { isAuthorized } from '@/utils/Helper';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default defineComponent({

  name: 'DeliveryCharge',
  components: { DeleteButton, EditButton, DeliveryChargeDelayTiming, DeliveryChargeAction, ItemTable },

  setup () {

    const store = useStore();
    const state = reactive({
      showAction: false,
      showDelayAction: false,
      columnMap: [

        { field: 'distance', header: 'Max Distance', sortable: false },
        { field: 'amount', header: 'Charge' },
        { field: 'max_order', header: 'Max Order', sortable: false },

      ],

      data: computed(() => store.getters['deliveryCharge/getCharges']),
      loading: computed(() => store?.state?.deliveryCharge?.loading),

      selectedItem: {},

    });

    const handleDataAction = (data, action) => {

      state.selectedItem = data;

      if (action === LIST_ITEM_ACTION_DELETE) {

        SweetAlert.confirmError({
          text: 'This will permanently Delete this delivery charge',
          preConfirm: handleDeleteData
        });

        return;
      }

      if (action === LIST_ITEM_ACTION_UPDATE) {
        state.showAction = true;
      }

    };

    const handleDeleteData = async () => {

      try {
        let response = await store.dispatch('deliveryCharge/deleteDeliveryCharge', state.selectedItem.id);

        Toaster.successAlt({
          message: response.data.message || 'Delivery Charge item Removed'
        })

        state.selectedItem = {};

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong'
        })

      }
    };

    const handleShowHideAction = () => {
      state.showAction = !state.showAction;
    };

    const handleUpdateAction = (updated) => {
      state.selectedItem = {};

      if (updated) {
        getData();
      }
    };

    const getData = async () => {
      const stateData = computed(() => store.getters['deliveryCharge/getCharges']);

      if (stateData.value.length) return;
      await store.dispatch('deliveryCharge/getDeliveryCharges');
    };

    onMounted(async () => {
      await getData();
    });

    return {
      state,
      handleShowHideAction,
      currency,
      handleDataAction,
      LIST_ITEM_ACTION_UPDATE,
      LIST_ITEM_ACTION_DELETE,
      handleUpdateAction,
      isAuthorized,
      permissions,
      permissionsGroup

    };
  }
});
</script>

<style scoped>

</style>
