<template>
  <modal-comp
      :title-text="state.isUpdating ?'Update Delivery Charge' : 'Create delivery Charge' "
      :mode="state.isUpdating ? 'update' : 'create'"
      :show="state.showAction"
      @hide="fireEvent('update:modelValue', false)"
      @submit="handleDataSave"
      :loading="state.isFormSubmitting"
      title-icon-class="me-1 icon-local_shipping"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-12">
            <label for="distance" class="col-form-label">
              Max Distance:
              <span class="text-danger">*</span>
            </label>
            <input
                type="number"
                class="form-control"
                id="distance"
                placeholder="Maximum distance"
                v-model.trim="state.form.distance.value"
                autocomplete="off"
                required
            >
            <form-error-msg :error="state.form.distance.error"></form-error-msg>
          </div>

          <div class="form-group col-12">
            <label for="charge" class="col-form-label">
              Charge Amount:
              <span class="text-danger">*</span>
            </label>
            <input
                type="number"
                step="0.1"
                class="form-control"
                id="charge"
                placeholder="Delivery Charge"
                v-model.trim="state.form.amount.value"
                autocomplete="off"
                required
            >
            <form-error-msg :error="state.form.amount.error"></form-error-msg>
          </div>

          <div class="form-group col-12">
            <label for="max-order" class="col-form-label">
              Max Order:
              <span class="text-danger">*</span>
              <form-input-help>Order total exceeding this amount will lift the charge</form-input-help>
            </label>
            <input
                type="number"
                step="0.1"
                class="form-control"
                id="max-order"
                placeholder="Maximum order amount"
                v-model.trim="state.form.max_order.value"
                autocomplete="off"
                required
            >
            <form-error-msg :error="state.form.max_order.error"></form-error-msg>
          </div>

        </div>
      </div>
    </div>
  </modal-comp>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import { isEmptyObject } from '@/utils/Helper';
import FormInputHelp from '@/components/Util/FormInputHelp';
import useVuelidate from '@vuelidate/core';
import { decimal, minValue } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import Notification from '@/utils/Notification';
import { useStore } from 'vuex';
import Toaster from '@/utils/Toaster';

export default defineComponent({
  name: 'DeliveryChargeAction',
  components: { FormErrorMsg, FormInputHelp, ModalComp },
  emits: ['update:modelValue', 'shown', 'hidden', 'updated'],
  props: {

    modelValue: {
      type: Boolean,
      default: false
    },

    itemToUpdate: {
      type: Object,
      default: () => {
      }
    },

  },
  setup (props, { emit }) {

    const store = useStore();
    const state = reactive({

      showAction: props.modelValue,

      isFormSubmitting: false,

      isUpdating: false,

      form: {
        distance: {
          error: false,
          value: 0
        },
        amount: {
          error: false,
          value: 0,
        },
        max_order: {
          error: false,
          value: 0,
        }
      },

    });

    const populateAddUpdateForm = (nv) => {

      const isCreating = isEmptyObject(nv);
      state.isUpdating = !isCreating;

      const populateForm = {
        distance: {
          error: false,
          value: isCreating ? '' : nv.distance
        },
        amount: {
          error: false,
          value: isCreating ? '' : nv.amount
        },
        max_order: {
          error: false,
          value: isCreating ? '' : nv.max_order
        },
      };

      if (!isCreating) {
        populateForm.id = nv.id;
      }

      state.form = populateForm;

    };

    const handleDataSave = async () => {

      const isFormValid = validateForm();

      if (!isFormValid) {
        Notification.warning('Fix error first');
        return;
      }

      await handleCreateUpdateData();

    };

    const handleCreateUpdateData = async () => {

      state.isFormSubmitting = true;

      const data = {
        amount: state.form.amount.value,
        distance: state.form.distance.value,
        max_order: state.form.max_order.value
      };

      try {

        let response = state.isUpdating
            ? await store.dispatch('deliveryCharge/updateDeliveryCharge', { id: state.form.id, data: data })
            : await store.dispatch('deliveryCharge/createDeliveryCharge', data);

        Toaster.successAlt({
          message: response.data.message || `Delivery Charge ${state.isUpdating ? 'Updated' : 'Created'}`
        })

        fireEvent('update:modelValue', false);
        fireEvent('updated');

      } catch (e) {

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.amount.error = ex(err.amount);
          state.form.distance.error = ex(err.distance);
          state.form.max_order.error = ex(err.max_order);
        });

        Toaster.error({
          message: e.message || 'Something went wrong'
        })

      }
      state.isFormSubmitting = false;
    };

    const validateForm = () => {

      ErrorHelper.checkValidity(validateData);

      const form = state.form;
      let formIsValid = true;

      for (let key in form) {

        if (form[key].error) {
          formIsValid = false;
          break;
        }

      }

      return formIsValid;
    };

    const fireEvent = (event, value) => emit(event, value);

    const validationRules = {

      form: {
        distance: {
          value: {
            decimal,
            minValue: minValue(1)
          }
        },

        amount: {
          value: {
            decimal,
            minValue: minValue(0)
          }
        },

        max_order: {
          value: {
            decimal,
            minValue: minValue(0)
          }
        }
      }
    };

    const validateData = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validateData, () => {

      ErrorHelper.getValidationErrors(validateData, (errors) => {

        state.form.amount.error = errors['form.amount.value'] || false;
        state.form.distance.error = errors['form.distance.value'] || false;
        state.form.max_order.error = errors['form.max_order.value'] || false;

      }, {
        'form.amount.value': 'Amount',
        'form.distance.value': 'Distance',
        'form.max_order.value': 'Maximum Order',
      });

    });

    watch(() => props.modelValue, (nv) => {
      let eventName = nv ? 'shown' : 'hidden';
      state.showAction = nv;
      fireEvent('update:modelValue', nv);
      fireEvent(eventName, nv);

    });

    watch(() => props.itemToUpdate, (nv) => populateAddUpdateForm(nv));

    return {
      state,
      fireEvent,
      handleDataSave
    };

  }
});
</script>
